<template>
  <div>
    <tw-header :title="title"></tw-header>
    <div class="tw_container">
      <tw-breadcrumbs :pages="breadcrumbs" style="margin-bottom: 40px" />

      <el-form class="search_unit compact" ref="searchUnit" :model="searchForm" label-position="top">
        <div class="row">
          <el-form-item class="max-content" :label="$t('Label.Process Update Datetime')" prop="processUpdateDateTime">
            <el-date-picker
              v-model="searchForm.processUpdateDateTime"
              type='datetimerange'
              range-separator='to'
              format='yyyy-MM-dd HH:mm'
              value-format='yyyy-MM-dd HH:mm:ss'
              :default-time="['00:00:00', '23:59:59']"
              start-placeholder='Start datetime'
              end-placeholder='End datetime'>
            </el-date-picker>
          </el-form-item>     
          <el-form-item :label="$t('Label.Section(From)')" prop="fromSectionIds">
            <TwSelectSection v-model="searchForm.fromSectionIds" :sections="fromSections" />
          </el-form-item>
          <el-form-item :label="$t('Label.Section(To)')" prop="toSectionIds">
            <TwSelectSection v-model="searchForm.toSectionIds" :sections="toSections" />
          </el-form-item>
        </div>

        <div class="row">
          <el-form-item :label="$t('Label.Process')" prop="categoryAndProcessType">
            <el-cascader popper-class="category-select" v-model="searchForm.categoryAndProcessType" placeholder="Select" :options="processes" :props="{value: 'code', 'label': 'name'}" :filter-method="filter" clearable filterable>
              <template slot-scope="{ data }">
                <div style="display: flex;align-items: center">
                  <tw-entity-icon v-if="data.entityId" :entityName="data.entityId" size="medium" color="color" style="margin-right: 8px;" />
                  <span>{{data.name}}</span>
                </div>
              </template>
            </el-cascader>
          </el-form-item>
          <el-form-item :label="$t('Label.Trading Name')" prop="tradingName">
            <el-input type="text" v-model="searchForm.tradingName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Flow Name')" prop="flowName">
            <el-input type="text" v-model="searchForm.tradingManageName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <tw-button type="secondary" size="small" class="toggle_button" :class="{ open: openForm }" @click="openToggle">
            <img src="@/assets/images/icons/view_all.svg">
          </tw-button>
        </div>

        <transition
          name="content"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
          @after-leave="afterLeave"
        >
          <div v-show="openForm">
            <div class="row">
              <el-form-item :label="$t('Label.Process User Memo')" prop="processUserMemo">
                <el-input type="text" v-model="searchForm.processUserMemo" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <!-- 関連PB：312, 933, 992（2024年9月月次リリース時点ではフロント関連箇所での「申請日時」の非表示対応としている） -->
              <!-- <el-form-item v-if="pageType === 'notAccepted'" class="max-content" :label="$t('Label.Application Datetime')" prop="applicationDateTime">
                <el-date-picker
                  v-model="searchForm.applicationDateTime"
                  type='datetimerange'
                  range-separator='to'
                  format='yyyy-MM-dd HH:mm'
                  value-format='yyyy-MM-dd HH:mm:ss'
                  :default-time="['00:00:00', '23:59:59']"
                  start-placeholder='Start datetime'
                  end-placeholder='End datetime'>
                </el-date-picker>
              </el-form-item> -->
              <el-form-item :label="$t('Label.Trading ID / Flow ID')" prop="tradingFlowId">
                <el-input type="text" v-model.trim="searchForm.tradingFlowId" placeholder="Enter keyword" class="trading-flow"></el-input>
              </el-form-item>
            </div>
          </div>
        </transition>

        <div class="row drop space_between">
          <div>
            <tw-button
              type="secondary"
              size="medium"
              icon="search"
              @click="search"
            >
              Search
            </tw-button>
            <tw-button
              type="default"
              size="medium"
              @click="clear"
              style="margin: 8px 16px 16px;"
            >
              Clear
            </tw-button>
          </div>

          <el-dropdown trigger="click" placement="bottom" @command="onDropDownClick" :tabindex="-1">
            <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
            <el-dropdown-menu class="nowrap">
              <el-dropdown-item command="tsv">{{$t('Label.TSV Download')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-form>

      <tw-table-system :schemas="schemas" :items="items" :pageSize="pageSize" :totalCount="totalCount" :offset="offset" :selection="selection" :serverPagination="true" @row-click="rowClick" @row-dbclick="rowDbClick" @paging="onPaging" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { DASHBOARD_PROCESS_SEARCH_SORT_TARGET, DEFAULT_PAGENATION } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import mixinEntity from '@/utils/mixinEntity.js';
import { collapseTransition } from '@/utils/nextFrame';
import { entityNames } from '@/dictionaries/map.js';
import { normalize, clearSearchForm, formatUtcDate } from '@/utils/searchUtil';
import schemasForConfirmationTo from '@/dictionaries/listConfirmationToSchema.json';
import schemasForNotAccepted from '@/dictionaries/listNotAcceptedSchema.json';

export default {
  name: 'ListConfirmationToAndNotAccepted',
  mixins: [mixinEntityList, mixinEntity, collapseTransition],
  data() {
    return {
      items: null,
      // プロセスプルダウン作成用
      categories: _.map(entityNames, o => {
        return {
          ...o,
          name: this.$t(`Entity.${o.nameEn}`)
        };
      }),
      // プロセスプルダウン
      processes: [],
      searchForm: {
        processUpdateDateTime: [],
        fromSectionIds: [],
        toSectionIds: [],
        categoryAndProcessType: [],
        tradingName: '',
        tradingManegeName: '',
        processUserMemo: '',
        // Not Acceptedの時のみ使用
        // applicationDateTime: [],
        tradingFlowId: '',
      },
      limit: DEFAULT_PAGENATION.LIMIT,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      lastQuery: {},
    };
  },
  computed: {
    pageType() {
      return {
        ConfirmationTo: 'confirmationTo',
        NotAccepted: 'notAccepted'
      }[this.$route.name];
    },
    title() {
      if(this.pageType === 'confirmationTo') return this.$t('BreadCrumbs.Confirmation To');
      else if(this.pageType === 'notAccepted') return this.$t('BreadCrumbs.Not Accepted');
      return ''
    },
    // 2024年6月月次時点、「○○CompanyIcon」・「○○CompanyName」がbffレスポンスにないため、schemaのdataTypeをCustomerではなくstringとする
    schemas() {
      if(this.pageType === 'confirmationTo') return schemasForConfirmationTo;
      else if(this.pageType === 'notAccepted') return schemasForNotAccepted;
      return []
    },
    breadcrumbs() {
      return [
        { label: this.$t('BreadCrumbs.Dashboard'), to: '/' },
        { label: this.title },
      ];
    },
    apiCode() {
      if(this.pageType === 'confirmationTo') return 'get_/v1/dashboard/process-to-confirm/search';
      if(this.pageType === 'notAccepted') return 'get_/v1/dashboard/processes-not-accepted';
      return '';
    }
  },
  created() {
    // if (this.pageType !== 'notAccepted') delete this.searchForm.applicationDateTime;

    this.processes = this.getProcesses();
    this.fetch();
  },
  methods: {
    // POSTパラメータを生成します
    transformQueries() {
      const f = this.searchForm;
      const processIds = this.getProcessIdsByProcessType(_.get(f.categoryAndProcessType, '1'))?.[0];

      const queries = {
        processUpdateDateFrom: f.processUpdateDateTime ? formatUtcDate(f.processUpdateDateTime[0]) : null,
        processUpdateDateTo: f.processUpdateDateTime?.[1] ? formatUtcDate(f.processUpdateDateTime[1]).replace('00Z', '59Z') : null,
        fromSectionIds: f.fromSectionIds,
        toSectionIds: f.toSectionIds,
        processIds: processIds,
        tradingName: f.tradingName,
        tradingManageName: f.tradingManageName,
        processUserMemo: f.processUserMemo,
        tradingId: this.getTradingFlowId(f.tradingFlowId)[0],
        tradingFlowId: this.getTradingFlowId(f.tradingFlowId)[1],
      };

      // if (this.pageType === 'notAccepted') {
      //   queries.requestDateFrom = f.applicationDateTime ? formatUtcDate(f.applicationDateTime[0]) : null;
      //   queries.requestDateTo = f.applicationDateTime?.[1] ? formatUtcDate(f.applicationDateTime[1]).replace('00Z', '59Z')  : null
      // }

      return {
        ...queries,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort
      };
    },
    fetch(queries) {
      this.cancelRequestSources.forEach(tag => {
        $api.cancelRequests(tag);
      });

      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: this.apiCode,
          query: queries || this.transformQueries()
        },
        tag: this.pushCancelTag(),
      };

      $api.request(params)
        .then(res => {
          this.items = res.processes;
          this.totalCount = res.totalCount;
          this.lastQuery = _.cloneDeep(params.lslConfig.query);
        })
        .catch(err => {
          if (err.isCanceled) {
            return;
          }
          this.items = [];
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    search() {
      this.offset = 0;
      this.fetch();
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
    },
    filter(node, keyword) {
      const keywords = node.pathNodes.reduce((ary, pathNode) => {
        if (Array.isArray(pathNode.data.keywords)) {
          return ary.concat(node.pathLabels).concat(pathNode.data.keywords);
        }
        return ary.concat(node.pathLabels);
      }, []);
      return keywords.some((str) => normalize(str).includes(normalize(keyword)));
    },
    onPaging({target, sortType, currentPage}) {
      if (target && sortType) {
        const key = _.get(_.find(this.schemas, {key: target}), 'sortTarget');
        this.sort = {
          target: [DASHBOARD_PROCESS_SEARCH_SORT_TARGET[key] || target],
          sortType: [sortType],
        };
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      const queries = {
        ...this.lastQuery,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
      this.fetch(queries);
    },
    // TSVダウンロード用に全件取得
    fetchAll() {
      return new Promise((resolve, reject) => {
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: this.apiCode,
            query: { ...this.transformQueries(), offset: undefined, limit: undefined }
          }
        };

        $api.request(params)
        .then(res => {
          resolve(res.processes);
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          reject();
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.search_unit {
  & .max-content {
    width: max-content;
  }
}
</style>
